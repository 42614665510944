import * as React from "react"
import { slide as Menu } from 'react-burger-menu'

class MobileMenu extends React.Component  {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  
  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu () {
    this.setState({menuOpen: false})
  }

  render() {
    const items = [
      {
        name: 'Our family',
        to: '/#family',
      },
      {
        name: 'Our service',
        to: '/#support',
      },
      {
        name: 'Transport',
        to: '/#transport',
      },
      {
        name: 'Planning Ahead',
        to: '/#planning',
      },
      {
        name: 'Prices',
        to: '/#prices',
      },
      {
        name: 'Feedback',
        to: '/#feedback',
      },
      {
        name: 'Contact',
        to: '/#contact-us',
      },
      {
        name: 'Back to top',
        to: '/#top'
      },
    ];
  
    var styles = {
      bmBurgerButton: {
        position: 'absolute',
        width: '36px',
        height: '30px',
        left: '20px',
        top: '20px'
      },
      bmBurgerBars: {
        background: '#D1B771'
      },
      bmBurgerBarsHover: {
        background: '#FFFFFF'
      },
      bmCrossButton: {
        height: '24px',
        width: '24px'
      },
      bmCross: {
        background: '#D1B771'
      },
      bmMenuWrap: {
        position: 'fixed',
        height: '100%'
      },
      bmMenu: {
        background: '#191919',
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em',
        overflowY: 'hidden',
      },
      bmMorphShape: {
        fill: '#373a47'
      },
      bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
      },
      bmItem: {
        display: 'block',
        color: '#FFFFFF',
        marginBottom: '10px',
        fontWeight: '700',
      },
      bmOverlay: {
        background: 'rgba(0, 0, 0, 0.8)'
      }
    }
    
    return (
      <div className="hide-in-desktop">
        <Menu 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          left disableAutoFocus
          styles={styles}
        >
          {items.map((item) => (
            <a onClick={() => this.closeMenu()} key={item.name.toString()} href={item.to} className="menu-item">{item.name}</a>
          ))}
        </Menu>
      </div>
    )
  }
}

export default MobileMenu