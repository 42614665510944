import * as React from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import classNames from "classnames"
import * as headerStyle from "../components/Header.module.css"
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from 'gatsby-plugin-smoothscroll';

const items = [
  {
    name: 'Our family',
    to: '#family',
  },
  {
    name: 'Our service',
    to: '#support',
  },
  {
    name: 'Transport',
    to: '#transport',
  },
  {
    name: 'Planning Ahead',
    to: '#planning',
  },
  {
    name: 'Prices',
    to: '#prices',
  },
  {
    name: 'Feedback',
    to: '#feedback',
  },
  {
    name: 'Contact',
    to: '#contact-us',
  },
];

const Header = props => {
  return (
    <header className={headerStyle.header}>
      <Grid>
        <Row middle="xs" center="xs" start="md">
          <Col xs={12}>
            <Row center="xs">
              <Col className="gold" xs={12}>
                <StaticImage
                  src="../images/rose.png"
                  alt="Logo"
                  width="50"
                  height="50"
                  className={classNames([headerStyle.logoMobile, 'hide-in-desktop'])} />
                <StaticImage
                  src="../images/rose.png"
                  alt="Logo"
                  width="100"
                  height="100"
                  className={classNames([headerStyle.logoDesktop, 'hide-in-mobile'])} />
                <h1 className={headerStyle.title}>{props.title}</h1>
                <span className={headerStyle.strapline}>{props.strapLine}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={classNames([headerStyle.menuWrapper, 'hide-in-mobile'])}>
            <Row center="xs">
              <Col xs={12} sm={12} lg={10}>
                <ul className={headerStyle.menu}>
                  {items.map((item, index) => (
                    <li key={item.name.toString()}>
                      <span onClick={() => scrollTo(item.to)} role="button" tabindex={index} className={headerStyle.menuItem}>{item.name}</span>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </header>
  )
}

export default Header