import * as React from "react"
import { Formik, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { Grid, Row, Col } from "react-flexbox-grid"
import Recaptcha from "react-google-recaptcha"
import classNames from "classnames"
import * as buttonStyle from "../components/Button.module.css"

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY

if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`
    Env var SITE_RECAPTCHA_KEY is undefined! 
    You probably forget to set it in your Netlify build environment variables. 
    Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
    Note this demo is specifically for Recaptcha v2
  `)
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Contact = props => {

  const recaptchaRef = React.createRef()

  const formSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(40, 'Too long!').required('Name is required.'),
    email: Yup.string().email('Invalid email').required('Email is Required.'),
    enquiry: Yup.string().required('Enquiry is required.'),
  });

  return (
    <Grid>
      <Row>
        <Col mdOffset={2} xs={10} md={3} className="row-margin-bottom">
          {props.children}
        </Col>
        <Col xs={10} md={5}>
          <Formik
            initialValues={{
              name: '',
              email: '',
              enquiry: '',
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={formSchema}
            onSubmit={(values, actions) => {
              const recaptchaValue = recaptchaRef.current.getValue()
              fetch('/?no-cache=1', {
                method: 'POST',
                headers: { 'Content-type': 'application/x-www-form-urlencoded' },
                body: encode({
                  'form-name': 'contact',
                  'g-recaptcha-response': recaptchaValue,
                  name: values.name,
                  email: values.email,
                  enquiry: values.enquiry,
                }),
              })
              .then(() => {
                if (recaptchaValue) {
                  actions.resetForm()
                  actions.setStatus({
                    message: 'Thank you for your email, we\'ll be in touch with you shortly. Parkers.',
                    css: 'success'
                  })
                  recaptchaRef.current.reset()
                  actions.setSubmitting(false)
                }
                else {
                  actions.setStatus({
                    message: 'Please tick the captcha box.',
                    css: 'error'
                  })
                  recaptchaRef.current.reset()
                  actions.setSubmitting(false)
                }
              })
              .catch(error => {
                actions.setStatus({
                  message: 'We\'re really sorry but we didn\'t receive your email. Please try again later or feel free to give us a call at 01626 437001.',
                  css: 'error'                  
                })
                recaptchaRef.current.reset()
                actions.setSubmitting(false)
              })
            }}
            render={({
              isSubmitting,
              handleSubmit,
              handleReset,
              status,
            }) => (
              <form
                id="contact"
                name="contact"
                className="form contact-form"
                method="POST"
                data-netlify="true"
                onSubmit={handleSubmit}
                onReset={handleReset}
                data-netlify-recaptcha="true"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="form-item form-item-first">
                  <ErrorMessage component="div" className="form-error" name="name" />
                  <label htmlFor="name" aria-label="Name"></label>
                  <Field type="text" className="textfield" id="name" name="name" placeholder="Name" />
                </div>
                <div className="form-item">
                  <ErrorMessage component="div" className="form-error" name="email" />
                  <label htmlFor="email" aria-label="Email"></label>
                  <Field className="textfield" name="email" id="email" type="email" placeholder="Email" />
                  </div>
                <div className="form-item form-item-last">
                  <ErrorMessage component="div" className="form-error" name="enquiry" />
                  <label htmlFor="enquiry" aria-label="Enquiry"></label>
                  <Field className="textarea" name="enquiry" id="enquiry" component="textarea" placeholder="Enquiry" />
                </div>
                <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
                <div className="form-item form-submit">
                  <button className={classNames([buttonStyle.button, buttonStyle.buttonLarge, buttonStyle.buttonMargin, buttonStyle.buttonGold])} type="submit" disabled={isSubmitting}>Submit</button>
                </div>
                <div className={`form-status ${status ? status.css : ''}`}>
                  {status ? status.message : ''}
                </div>
              </form>
            )}
          />
        </Col>
      </Row>
    </Grid>
  )
}

export default Contact