import * as React from "react"
import { Col } from "react-flexbox-grid"
import classNames from "classnames"
import * as testimonialStyle from "../components/Testimonial.module.css"

const Testimonial = props => {

  return (
    <Col xs={12} className={classNames([testimonialStyle.wrapper, 'brick', props.classes])}>
      {props.children}
      <div>
        <div className={testimonialStyle.name}>{props.name}</div>
        <div className={testimonialStyle.date}>{props.date}</div>
      </div>
    </Col>
  )
}

export default Testimonial