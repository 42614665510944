import * as React from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import Header from "../components/Header"
import Mobilemenu from "../components/Mobilemenu"
import Testimonial from "../components/Testimonial"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Button from "../components/Button"
import { StaticImage } from "gatsby-plugin-image";
import Standard from "../files/standard.pdf"
import Torbay from "../files/torbay_fees.pdf"
import ExeDev from "../files/exeter_devon_fees.pdf"
import Terms from "../files/terms_business.pdf"
import Disclosure from "../files/disclosure.pdf"
import PrePaidInfo from "../files/pre_paid_info.pdf"
import OptionalExtras from "../files/optional_extras.pdf"

export default function Index() {

  return (
    <>
      <Grid fluid id="top">
        <Row>
          <Mobilemenu />
          <Col sm={12}>
            <Header title="Parker's" strapLine="Independent Family Funeral Directors"/>
          </Col>
        </Row>
        <Grid>
          <Row center="xs" middle="xs" start="md">
            <Col xs={12} md={5}>
              <h3>Your family funeral directors</h3>
              <p>We are a family run service which aims to help you and your family, every step of the way, at your time of need.</p>
              <p>We have two premises located in Newton Abbot and Bovey Tracey.</p>
              <p>Here at Parker’s we have a range of services to choose from to suit every individual need. Whether you are deciding on a burial or cremation, we can guide you in the right direction and ensure all necessary arrangements are made to allow a unique, personal and dignified service.</p>
              <p>With one simple phone call, we can guide you through this difficult time and create the perfect send off for your loved one.</p>
            </Col>
            <Col xs={12} md={2} className="switch-to-bottom">
              <Row center="xs">
                <StaticImage src="../images/rose.png" width="50" height="50" alt="Logo"/>
              </Row>
            </Col>
            <Col xs={12} md={5} className="row-margin-bottom">
              <Row center="xs">
                <Col xs={12} className="box-shadow">
                  <StaticImage src="../images/family.png" width="465" height="445" layout="constrained" alt="Parker family"/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
        <Grid id="family">
          <Row center="xs" start="md" middle="xs">
            <Col className="switch-to-bottom" xs={12} md={5}>
              <Row middle="xs" center="xs">
                <Col xs={8} md={5} className="col-margin-bottom img-large box-shadow">
                  <StaticImage src="../images/nick.png" alt="Nick Parker" layout="constrained" width="185" height="265"/>
                </Col>
                <Col mdOffset={1} xs={8} md={5} className="col-margin-bottom img-large box-shadow">
                  <StaticImage src="../images/jamie.png" alt="Jamie Parker" layout="constrained" width="185" height="265"/>
                </Col>
              </Row>
              <Row middle="xs" center="xs">
                <Col xs={8} md={3} className="col-margin-bottom img-small box-shadow">
                  <StaticImage src="../images/karen.png" alt="Karen Parker" layout="constrained" width="105" height="150"/>
                </Col>
                <Col mdOffset={1} xs={8} md={3} className="col-margin-bottom img-small box-shadow">
                  <StaticImage src="../images/laura.png" alt="Laura Parker" layout="constrained" width="105" height="150"/>
                </Col>
                <Col mdOffset={1} xs={8} md={3} className="col-margin-bottom img-small box-shadow">
                  <StaticImage src="../images/sam.png" alt="Sam Lowe" layout="constrained" width="105" height="150"/>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2} className="switch-to-bottom">
              <Row center="xs">
                <StaticImage src="../images/rose.png" width="50" height="50" alt="Logo"/>
              </Row>
            </Col>
            <Col className="switch-to-top" xs={12} md={5}>
              <h3>The Parker family</h3>
              <p><strong><em>Family Run Funeral Directors in Newton Abbot "Our Family to Your Family"</em></strong></p>
              <p>We are a local family service located in the heart of Newton Abbot and Bovey Tracey. We are all family who work here and we take alot of pride in offering a caring and professional service to our community when called upon in their time of need.</p>
              <p>We provide a very unique service with personal and attentive care which only a family can give. We treat every person as one of our own.</p>
              <p>If you have any questions or worries, please call. We are always on the other end of the phone.</p>
              <p>Nick, Jamie, Karen, Laura and Sam.</p>
              <p>(The Parker Family)</p>
            </Col>
          </Row>
        </Grid>
        <div id="support">
          <Grid>
            <Row center="xs">
              <Col xs={12}>
                <h3 className="no-margin-top">Funeral products</h3>
                <p>From the moment you make contact, we will guide you through step-by-step to create the best and most personal service possible for your loved one.</p>
                <p>There are many options which you can choose from: non religious service, church services or green burials. All of which we can cater for.</p>
                <p>We have two offices which we work from; one is located in Newton Abbot and the other is in Bovey Tracey. We have chosen these locations as we are a Newton Abbot family and we grew up in Bovey Tracey.</p>
                <p>We now have our own service chapel in Newton Abbot which can seat up to 70 people, with an added option of a room to hold refreshments after the service.</p>
              </Col>
            </Row>
          </Grid>
        </div>
        <Grid id="prices">
          <Row center="xs" middle="xs">
            <Col xs={12}>
              <h3>Prices</h3>
              <div className="prices">
                <a href={Standard} className="" target="_blank" rel="noreferrer"><Button>Standard Prices</Button></a>
                <a href={Torbay} target="_blank" rel="noreferrer"><Button>Torbay Crematorium Prices</Button></a>
                <a href={ExeDev} target="_blank" rel="noreferrer"><Button>Exeter and Devon Crematorium Prices</Button></a>
                <a href={Terms} target="_blank" rel="noreferrer"><Button>Terms of Business</Button></a>
                <a href={Disclosure} target="_blank" rel="noreferrer"><Button>Disclosure of interest</Button></a>
                <a href={OptionalExtras} target="_blank" rel="noreferrer"><Button>Optional Extras</Button></a>
              </div>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row center="xs" middle="xs">
            <Col xs={12} md={6}>
              <h3>Locations</h3>
            </Col>
          </Row>
          <Row center="xs" middle="xs">
            <Col xs={12} md={7}>
              <div style={{ width: '100%', height: '0px', position: 'relative', paddingBottom: '55%'}}><iframe src="https://streamable.com/e/mr3wbh?loop=0" frameborder="0" width="100%" height="100%" allowfullscreen style={{ width: '100%', height: '100%', position: 'absolute', left: '0px', top: '0px', overflow: 'hidden' }}></iframe></div>
            </Col>
          </Row>
        </Grid>
        <Grid className="full-width bg-black">
          <Row center="xs" start="md" middle="xs">
            <Col className="col-contained" xs={12} md={5} mdOffset={1}>
              <h4 className="no-margin-top">Newton Abbot Office</h4>
              <ul className="rose">
                <li>24 hour care and attention</li>
                <li>Chapel of rest</li>
                <li>Funeral cars</li>
                <li>Funeral flowers</li>
                <li>Green funerals</li>
                <li>Horse drawn carriages</li>
                <li>Non-religious funerals</li>
                <li>Monumental masons &#38; memorials</li>
                <li>Pre paid funerals</li>
              </ul>
            </Col>
            <Col xs={12} md={6} className="no-padding">
              <iframe title="Newton Abbot office location" height="500px" width="100%" frameBorder="0" style={{Border: 0}} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCHXw19pKAhS3IbStQr_hlIbIiRLj1QzW0&q=Parkers+Funeral+Directors"></iframe>
            </Col>
          </Row>
          <Row center="xs" start="md" middle="xs">
            <Col xs={12} md={6} className="no-padding switch-to-bottom">
              <iframe title="Bovey Tracey office location" height="500px" width="100%" frameBorder="0" style={{Border: 0}} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCHXw19pKAhS3IbStQr_hlIbIiRLj1QzW0&q=87+fore+street+bovey+tracey"></iframe>
            </Col>
            <Col xs={12} md={5} mdOffset={1}className="col-contained">
              <h4 className="no-margin-top">Bovey Tracey Office <span>(please call for an appointment)</span></h4>
              <ul className="rose">
                <li>24 hour care and attention</li>
                <li>Funeral cars</li>
                <li>Funeral flowers</li>
                <li>Green funerals</li>
                <li>Pre paid funerals</li>
                <li>Horse drawn carriages</li>
                <li>Non-religious funerals</li>
                <li>Monumental masons &#38; memorials</li>
              </ul>
            </Col>
          </Row>
        </Grid>
        <Grid id="transport">
          <Row center="xs" >
            <Col xs={12} className="row-margin-bottom">
              <h3>Transport</h3>
              <p>At Parker’s, we understand how important it is to be able to travel to the service with your family and friends as they help you through these difficult times. We have the traditional Jaguar Hearse and Limousine, as well as a choice of a 4 seater car if required. If you are after something a little more unique, we also have options such as, a Horse and Carriage, Bike Hearse or our exclusive made Classic Austin Mini hearse (Extra cost will apply).</p>
              <p>We take a lot of pride in looking after our vehicles and making sure they are perfect for the day.</p>
              <p>There are many different options which are available to make things more personal. For example a horse and carriage, lorries, vans, tractors and even boats.</p>
            </Col>
          </Row>
          <Row middle="xs" className="transport-images">
            <Col xs={12} md={5} className="box-shadow">
              <StaticImage src="../images/carsv2.png" width="465" height="350" layout="constrained" alt="Our cars"/>
            </Col>
            <Col xs={12} md={5} mdOffset={2} className="box-shadow">
              <StaticImage src="../images/horses.png" width="465" height="350" layout="constrained" alt="Horse and Carriage"/>
            </Col>
          </Row>
          <Row middle="xs">
            <Col xs={12} md={8} mdOffset={2} className="box-shadow">
              <StaticImage src="../images/austin.png" width="755" height="565" layout="constrained" alt="Classic Austin Mini"/>
            </Col>
          </Row>
        </Grid>
        <Grid id="planning">
          <Row center="xs" start="md" middle="xs">
            <Col xs={12}>
              <h3>Planning ahead</h3>
              <p>There comes a time in all of our lives when we think about planning for the future and what it would mean for our loved ones. A funeral plan puts your wishes on paper, which makes it more personal. It also allows you to make financial provision for your funeral which can alleviate the expense and distress at a time of need for family and friends.</p>
              <p>A plan covers the vast majority of the funeral cost. However, there are third party expenses beyond our control called disbursements. Disbursements are doctors’ fees, crematorium and burial fees. If these costs are not covered at the time of need, there may be a balance to pay.</p>
              <p>We offer a number of different options to help get the right plan for you. This means that there are affordable payments available. Or, a one-off payment can be selected to meet your personal needs.</p>
              <h4>About our pre-paid funeral plans</h4>
              <p>Planning ahead for your funeral is one of the most thoughtful and caring things you can do. Some people never get round to discussing funeral wishes with their loved ones, which can lead to all sorts of worry and anxiety when the time comes.</p>
              <p>Taking out a funeral plan is a simple and straightforward way to remove this worry, and it can provide real financial benefits too. Some of the other benefits include:</p>
              <ul>
                <li>Pre-arrange the details of your funeral and record any special wishes, such as hymns, music choice and any other personal touches you would like your plan to include</li>
                <li>Make financial provision for your funeral</li>
                <li>Choose your funeral director in advance</li>
                <li>Ensure your family has access to expert help and advice at what can be a difficult time.</li>
              </ul>
              <p>Our funeral plan is available to UK residents and there are no complicated medical forms to complete. Youcan also appoint a nominated representative who we will keep informed about your plan, according to your instructions.</p>
              <p>Our plans cover our costs (as specified in the plan paperwork) as long as we carry out the funeral and your requirements don’t change. You can also make a contribution towards third party costs, such as the cremation fees. These costs are outside of our control.</p>
              <p>For more information, please download our <a href={PrePaidInfo} target="_blank" rel="noreferrer">funeral plans brochure.</a></p>
              <h5>How your funds are held</h5>
              <p>All funeral plan pre-payments are held in an individual whole of life assurance policy for your peace of mind.</p>
              <h5>Backed by a trusted, national provider</h5>
              <p>Our funeral plans are provided by Ecclesiastical Planning Services Limited, which is part of a specialist, UK-based financial services group.</p>
              <h5>Flexible payment options</h5>
              <p>You can choose to pay for your funeral plan by lump sum or in instalments. There is an additional cost forpaying over two or more years and eligibility criteria applies. Please refer to the <a target="_blank" href="https://www.funeralplans.co.uk/documents/EPS%20Instalment%20Q%26A%20%28PDF%29.pdf">instalment Q&A</a> and the <a target="_blank" href="https://www.funeralplans.co.uk/documents/EPS-Funeral-Plan-Terms-and-Conditions.pdf">Terms and Conditions</a> for more information. Your funeral director will be able to provide you with apersonalised quote.</p>
              <p>Once your funeral plan is in place, you’ll receive a welcome booklet containing the details of your plan and your funeral plan certificate. Your nominated representative will also receive a copy if you request this.</p>
              <p><a target="_blank" href="https://www.funeralplans.co.uk/documents/EPS-Funeral-Plan-Terms-and-Conditions.pdf">Terms and Conditions</a> apply to funeral plans – please ask us if you have any questions.</p>
              <p style={{ fontSize: '12px', marginBottom: '20px'}}>We are an Appointed Representative of Ecclesiastical Planning Services Limited Reg. No. 2644860, registered in England at Benefact House, 2000 Pioneer Avenue, Gloucester Business Park, Brockworth, Gloucester, GL3 4AW, UK, which is authorised and regulated by the Financial Conduct Authority. Firm Reference Number 958152. The Financial Conduct Authority regulates pre-paid funeral plans only.</p>
            </Col>
          </Row>
          <Row center="xs" middle="xs">
            <Col xs={12}>
              <Button id="#contact-us">Contact us today</Button>
            </Col>
          </Row>
        </Grid>
        <div id="feedback" className="bg-black">
          <Grid>
            <Row center="xs">
              <Col xs={12}>
                <h3 className="no-margin-top">Feedback from our families</h3>
                <p>We always try to provide a personalised service for each customer, so we welcome you to contact us at <a className="gold underline" href="mailto:parkersfd@gmail.com?Subject=General%20enquiry">parkersfd@gmail.com</a> with your thoughts and comments.</p>
              </Col>
            </Row>
          </Grid>
        </div>
        <Grid>
          <div className="brick-layout">
            <Testimonial name="Pauline Boughton" date="01/2022">
              <p>I can not recommend Parkers enough, they truly went the extra mile and made a difficult time so much easier. They treated my husband with dignity and respect and in such a caring and compassionate way. Not only did they look after my hubby but they took time each week to check that I was ok too, what a kind and touching thing to do. The Order of Service was one of the best I have ever seen, it was perfect, just as the day of the funeral, and the weeks leading up to it, were too. Parkers took the stress out of my husband's funeral, I knew we were in safe hands, they guided me every step of the way. The whole Parker Family are caring, compassionate, do not rip you off, and are extremely genuine, a rarity these days but It's what you get from this family run business. They look after you as if you are part of their family. I can't speak highly enough of them, they are the best and I will recommend them at every opportunity I have. Thank You Parkers from the bottom of my heart.</p>
            </Testimonial>
            <Testimonial name="Debbie Walker" date="11/2021">
              <p>We were treated so nicely by all the Parker family and my mother in laws funeral was really simple but exactly what she would of wanted. You all treated us with respect and all I can say is what a lovely family you are and thanks again for your help regarding all we had to arrange. We will definitely recommend your services.</p>
            </Testimonial>
            <Testimonial name="Amy Barnett" date="16/04/2019">
              <p>Thank you nick and staff at Parker’s for everything you did for my family over the past 2 weeks after loosing my grandfather. The professionalism we all received from Parker’s is incredible & not only that the kindness and compassion too. It was so reassuring to know my grandfather was in safe kind hands. Nick even stayed on for me the evening before the service as I was travelling from Somerset to Devon to see my grandfather one last time. So again, thank you so much.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Joy Mott" date="01/03/2019">
              <p>Nick and the Parker family Thank you all for the perfect service you provided for my sister and I as we dealt with the passing of our mum. When we first made contact on the phone you were so helpful, sensitive, reassuring and helpful which continued when we had our meeting with Nick at the Bovey office; nothing was too much trouble! On the day of mum’s final farewell everything went so smoothly and having a friendly face in Nick present was lovely. I am so grateful for all the team have done over the last few weeks. The Order of Service was perfect and totally reflected my mum’s life and the photo of mum and dad together was beautiful. To receive a phone call from Nick the evening before the funeral to ask how I was and if there was anything else they could do for our family was so thoughtful - nothing was too much trouble. Thank you all again.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Lisa Porter" date="22/02/2019">
              <p>Message Thank you to Nick and his family for making a sad day seem perfect and stress free, you could not have done anymore for us. On behalf of my family we truly are thankful of every detail. You've made us proud and I know my dear brother would feel the same too. You come highly recommended and will always appreciate what you have done</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Jeannie Prescott" date="18/02/2019">
              <p>I would like to thank you and your family for making the entire process stressfree, for going above and beyond the norm and for making the day just perfect. I went from wondering where to start with it all, to handing it all over to you. You are a lovely family but highly professional.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Clive and Jackie" date="13/02/2019">
              <p>I would like to thank nick and family for the wonderful funeral of my beloved nephew, nick and his family could not have done any more for the family and friends, we would highly recommend Parker’s funeral services, to any one , Kind regards</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Steve Green" date="22/12/2018">
              <p>Not only a superbly family run business, but one of the nicest families I know. care, consideration and thought are just some of the words I'd use to describe them. They did my Dad proud and I'm sure he was looking down at Nick, saying thank you, as we lay Dad to rest. I'll always be appreciative to them, for that day and their thoughts towards us as a family since. the best around.</p>
            </Testimonial>
            <Testimonial name="Charleigh Ineson" date="23/11/2018">
              <p>Can not express how grateful I am to everyone at Parker’s especially nick, after losing my first ever loved one my loving dad not knowing what to do or where to start nick was amazing. Dealt with everything and always kept me updated. I received not only phone calls about my dads funeral but also just to see if I was ok, not just a business for these people. I know my dad was in the best hands and that’s all I could ever ask for. So thank you! At such a sad time you guys really do make it manageable.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Naomi Mason" date="20/06/2018">
              <p>Words cannot describe how grateful I am for all you have done to give my dad a great send off. From the beginning to end you where all so amazing. You treated my dad with such dignity and respect. Thank you so much </p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Lesley James and Matthew's Family" date="">
              <p>I had a recommendation for a funeral director (Parker's Funeral Directors) They handled my late brothers funeral with dignity, respect and honour. Nick and his family treated myself and my family as one of their own which was very comforting. I would highly recommend Parker's Family Funeral Directors to anyone which would require love and support at such a difficult time.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Heather J" date="01/08/2017">
              <p>Thank you so very much for your kind caring work with us all during the past few difficult weeks. From the very first contact with you I felt we were in good kind hands. Everything was professionally and carefully covered to make sure we got it right for our dear lovely mum. It was like she was one of your own. On the day your team were so kind , caring and compassionate in their manner, conducting mums arrangements and transporting her for our final good bye. The whole process was gentle and stress free, with a comfortable knowledge that we could indeed ask for anything at any time and it would not be any trouble at all. Our whole family honestly can't thank you all enough. Just thank you all so very much.</p>
            </Testimonial>
            <Testimonial name="The Pring family" date="">
              <p>We would like to say a big thank you to Nick and his family for a helping us through a recent bereavement. Parkers gave guidance, help and support in an unobtrusive way, but were always there for us, to help us through what was a “difficult” time. Their compassion and respect was apparent from the first contact with them and they offered excellent advice and suggestions to make sure all arrangements were made in accordance with our wishes. Nick and his family took care of everything so that we didn’t have to worry about making arrangements for flowers, orders of service etc. There were even extremely thoughtful touches on the day. Having had previous experiences with funeral directors it was so refreshing that Nick showed complete compassion and respect without patronising humility. With Kind Regards</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Karen, Philip and Moira" date="">
              <p>I just wanted to send you an email to extend our thanks for all your help and support when taking care of Mum and her funeral arrangements. It was so important to us that we chose a funeral directors who echoed our own ethos of family and you didn't disappoint. Right from the start Nick and the family put us at ease and helped ensure that the difficult task of planning Mums funeral was stress free. A lot of care and attention was put into not only looking after Mum but also the arrangements for her send off. Gran, Philip and I are so grateful to have been able to travel with Mum to her ceremony. Nick, you were a wonderful support to us during that journey. As we have already said to you it's your sense of family that makes you stand out from the crowd. You treated Mum like on of your own and nothing was too much trouble for you. We appreciate your honesty, advice and guidance throughout planning Mums day. Thank you also for bringing Mum to me the day after the service and the care you showed when completing your duties. Kind Regards,</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Karen, Cain and family" date="">
              <p>We have recently lost out dad aged 92 years old. Having had experience of losing 2 close family members in recent years we have had to arrange funerals. We were recommended parkers by a friend who could not have been more satisfied. From the first phone contact to the days after the funeral my sister and I could not have been looked after more. Our dad was talked about and looked after with the greatest respect. The funeral was all about what we wanted as a family. We really do feel that the whole family team at parkers go that extra mile to make a sad time more bearable. And for that we thank them very much. </p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Mrs Isaac" date="">
              <p>Nick Parker and his family were wonderful when my late father died. They made what was a very difficult time less stressful and took of every detail as if he was a member of there own family. I would thoroughly recommend them!</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Tony, Paul and Nikki" date="">
              <p>Just a quick thank you to yourself, family and staff for looking after Margaret in such a professional, caring and dignified manner. Thank you all for taking the stress away from the family at this difficult time, ensuring she had a great send off. We really appreciate it. Kind regards and best wishes for the future.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Sarah" date="18/07/2016">
              <p>Just wanted to thank Nick & the whole team at Parkers on behalf my family. We were so impressed by the caring & respectful send off you arranged for my dad. Even when discussing the initial arrangements with mum you were keen for her to spend the minimum necessary without compromising on quality & attention to detail. We are so glad you were recommended by a friend to us in our time of grief.</p>
            </Testimonial>
            <Testimonial name="Peter Stevens" date="24/06/2016">
              <p>I want to say thank you for the wonderful way you and Jamie (and the rest of your family team, whom I didn't meet) handled Jacqui's funeral yesterday. It was solemn and respectful, but also human, without any of the somewhat artificial mournfulness I've encountered at such events before. Also, all the family and I are most grateful for the way you stepped back and allowed us to do things our way - in particular with regard to the coffin that was made by my son and daughter, and flowers - and in fact provided much valuable assistance in doing so.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Geoffrey" date="06/06/2016">
              <p>I like to thank you and all the whole Parkers team for the wonderful assembly in respect of the ceremony yesterday in Torquay . All this is an unforgettable memory , I speak on behalf of my whole family . In my life, I personally visited a number of ceremonies but I never experienced such a smooth celebration . It's the personality that counts and you and your team has plentiful.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Caroline R" date="20/05/2016">
              <p>Words really cannot express our gratitude to Nick, Karen, Sam, Jamie & Laura for looking after our beautiful mum, and indeed ourselves, with such care, kindness and respect. Knowing that mum was being left in their tender loving care made leaving her, after our many visits, so much more bearable. Their ability to read and understand both mum and our needs was really quite amazing resulting in us having all that we could have wished for, apart from bringing her back that is, from the moment we walked through their door through to the funeral, committal and thereafter. Their patience, commitment and devotion was a breath of fresh air as were the many laughs we shared which brought a welcome relief to the many tears at such a heart rendering time. Nothing was too much trouble no matter when or what it was especially our ‘scenic drive’. Thank you all so very much for providing and arranging such a bespoke service in every respect. </p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Jude Hitt" date="04/09/2015">
              <p>My family and I would like to say massive thanks to Parkers for the immaculate care provided for our mother on the sad occasion of her funeral. From the very first moment we rang Parkers, Nick and his family were brilliant and couldn’t have been more helpful, caring and sensitive to our needs. Considering we had only just made contact, they understood every nuance and detail of what was required and their professional, personal & courteous service throughout was exemplary. We will always be indebted for the wonderful send off they arranged for our mother. When they say “Our Family to Your Family” this sums up their outstanding service to a tee. An enormous thank you to Nick, Jamie, Karen and Laura - we certainly could not have asked to have been in better, more perfect hands.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Anna day" date="07/07/2015">
              <p>From the very start of this emotional time for our family, Nick Parker and his family took great care of our father and family. They were very dignified and caring to the point where we felt they were like part of our family and understood us al extremely well in such a short space of time. From our family and father to you, we will be eternally grateful for helping us arrange such a beautiful send off for Dad. Always available on the phone if we needed him and with such great sympathetic advice. Thank you so much.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Valerie Klippel" date="17/06/2015">
              <p>Could not have picked a better Funeral Directors when we had to contact one for Ralph's Funeral, The Parker family took excellent care of Ralph in his final journey, with professional care and empathy. All the little touches helped make a sad emotional time bearable. Thank you The Parker Family can't recommend you enough.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="S Bailey" date="09/05/2015">
              <p>Can not recommend this company enough.. nothing was ever too much trouble. Made us feel welcome and part of there family. Professional service with Empathy and Outstanding Care and Attention All From the Heart.. Its not just a job to the lovely Parkers family.</p>
            </Testimonial>
            <Testimonial name="Susan" date="26/08/2014">
              <p>Choosing a funeral director is probably one of the most important decision you make, but you don't realise that until you need one. The Parker family took excellent care of everyone and everything. Dad passed away in July and we chose Parkers because we wanted a family approach, Dad was well cared for and always referred to by name. Their care did not stop there, it was the little touches that made a sad and emotional experience more bearable. We felt we did the right thing for Dad. Thank you to everyone at Parkers, Nick, Jamie, Laura and Sam.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="S West" date="14/08/2014">
              <p>My grandmother passed away in late July this year, just 14 days before my mother was due to have a major operation. With Mum and I both located over 150 miles away we had a lot to do and not a lot of time! Nick and his family were amazing, both in terms of organising the funeral to a very tight timescale and providing a much needed friendly face on a very regular basis! Nick and his family certainly go the extra mile, and guided us through what can be an overwhelming process. When my Mother made the journey to Devon to register the death, Nick not only drove her to the register office, but also picked her up and got her to the hotel safely. I can not recommend this family enough, they help you to smile in very difficult circumstances.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Kathleen Lycett" date="04/02/2014">
              <p>Mum passed away in hospital on the 12th January.went into Parkers for a brochure and were immediately surrounded with compassion and concern. We spoke with Nick and he talked us through what we needed to do without any pressures. He told us where other directors were (we are new to the area ) but we already knew we wanted him and his lovely family to take care of Mum. He treated her with the utmost respect and the whole proceedings (although sad) became almost a happy family occasion and celebration of Mum's life. Such a lovely family and we can't thank them or praise them highly enough.</p>
            </Testimonial>
            <Testimonial classes="hide-in-mobile" name="Harry ryan" date="11/01/2014">
              <p>My father Cliff, passed away in hospital after a stroke. We had 22 days of being next to his bedside, so you can imagine how we felt when he lost his fight on the 18th December 2013, only a week before Christmas, and having to register his death and trying to sort out probate, we were in such a state we didn't know which way to turn. We found Nicks name and number on the internet, and chose him because he was an independent family business. From the moment we entered his shop, we knew we had picked the right one. He and his family helped us in so many ways, it made the whole ordeal of losing someone close so much easier to bear. We could visit my father laying in rest anytime, even Christmas Day if we wanted to. They showed so much care, consideration and above all, respect to my father and his family. We cannot thank and praise his family enough.</p>
            </Testimonial>
          </div>
        </Grid>
        <Grid className="no-padding">
          <Row center="xs">
            <StaticImage src="../images/rose.png" width="50" height="50" alt="Logo"/>
          </Row>
        </Grid>
        <Contact>
          <h3 id="contact-us" className="no-margin-top">Contact us</h3>
        </Contact>
        <Footer
          title="Parker's"
          colour="black"
        />
      </Grid>
    </>
  )
}

export function Head() {
  return (
    <>
      <title>Parker's | Independent funeral directors</title>
      <meta name="description" content="An independent family run funeral directors from Newton Abbot, Devon." />
      <meta name="keywords" content="Parkers, funeral, family directors, newton abbot, family, devon, directors, funeral, family run, bovey tracey" />
      <meta name="robots" content="follow, index" />
      <meta property="og:title" content="Parker's" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://www.parkersfd.co.uk/shop.png" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content="The Parker family and shop front." />
      <meta name="og:email" content="parkersfd@gmail.com"/>
      <meta name="og:phone_number" content="01626 437001"/>
      <meta name="og:street-address" content="68 Queen street"/>
      <meta name="og:locality" content="Newton Abbot"/>
      <meta name="og:region" content="Devon"/>
      <meta name="og:postal-code" content="TQ12 2ES"/>
      <meta name="og:country-name" content="USK"/>
      <link rel="canonical" href="https://www.parkersfd.co.uk" />
      <link rel="shortlink" href="https://www.parkersfd.co.uk" />
    </>
  
  )
}
