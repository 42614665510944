import * as React from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import classNames from "classnames"
import * as footerStyle from "../components/Footer.module.css"

const Footer = props => {
  var date = new Date();

  return (
    <>
      <footer>
        <div className="bg-grey">
          <Grid>
            <Row center="xs" start="md">
              <Col xs={12} md={2}>
                <h5 className="no-margin-top">Newton Abbot</h5>
                <div>9 Devon Square</div>
                <div>Newton Abbot</div>
                <div>Devon</div>
                <div>TQ12 2HN</div>
              </Col>
              <Col xs={12} md={2}>
                <h5 className="no-margin-top">Bovey Tracey</h5>
                <div>87 Fore street</div>
                <div>Bovey Tracey</div>
                <div>Devon</div>
                <div>TQ13 9AB</div>
              </Col>
              <Col mdOffset={4} xs={12} md={4} className="right-aligned">
                <h5 className="no-margin-top"> Contact us 24 hours a day, 7 days a week.</h5>
                <span className={footerStyle.linkWrapper}>Tel: <a className={classNames([footerStyle.link, 'underline'])} href="tel:01626437001"> 01626 437001</a></span>
                <span className={footerStyle.linkWrapper}>Email: <a className={classNames([footerStyle.link, 'underline'])} href="mailto:parkersfd@gmail.com?Subject=General%20enquiry">parkersfd@gmail.com</a></span>
                <a className={footerStyle.link} href="https://www.facebook.com/Parkers-Independent-Family-Funeral-Directors-134451466749432/" target="_blank" rel="noreferrer"><img src={'/fb.png'} alt="facebook icon" /></a>
              </Col>
            </Row>
          </Grid>
        </div>
        <div className="bg-black">
          <Grid className={footerStyle.footerBottom}>
            <Row center="xs">
              <Col xs={6}>
                Copyright &copy; ParkersFD {date.getFullYear()}
              </Col>
            </Row>
          </Grid>
        </div>
      </footer>
    </>
  )
}

export default Footer