import * as React from "react"
import classNames from "classnames"
import * as buttonStyle from "../components/Button.module.css"
import scrollTo from 'gatsby-plugin-smoothscroll';

const Button = props => {
  const needsScroll = props.id;

  if (needsScroll) {
    return (
      <button onClick={() => scrollTo(needsScroll)} className={classNames([buttonStyle.button])}>{props.children}</button>
    )
  }
  else {
    return (
      <button className={classNames([buttonStyle.button])}>{props.children}</button>
    )
  }
  
}

export default Button